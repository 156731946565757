/**
 * The toastr configs
 */

const toastrOptions = {
    timeOut: 2500,
    tapToDismiss: true,
    closeButton: true,
    closeDuration: 100,
    preventDuplicates: true,
    progressBar: true,
};

window.toastr.options = toastrOptions;
